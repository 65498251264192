import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth"
// import { getFirestore } from 'firebase/firestore'
import { getAnalytics, setUserProperties } from "firebase/analytics";

export default defineNuxtPlugin(() => {
  const { firebase, appId } = useRuntimeConfig().public;

  if (!firebase.apiKey) {
    console.error("Firebase disabled, missing API key");
    return;
  }

  const app = initializeApp(firebase);

  const analytics = getAnalytics(app);
  setUserProperties(analytics, { assistant_id: appId });

  // const auth = getAuth(app);
  // nuxtApp.vueApp.provide('auth', auth);
  // nuxtApp.provide('auth', auth);

  // const firestore = getFirestore(app);
  // nuxtApp.vueApp.provide('firestore', firestore);
  // nuxtApp.provide('firestore', firestore);
});
