import { default as chatdf8mRyzAVdMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/chat.vue?macro=true";
import { default as forgot_45passwordMgh7JPxaodMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/forgot-password.vue?macro=true";
import { default as indexntAewQPBcpMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/index.vue?macro=true";
import { default as loginjr3mrz6PDSMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/login.vue?macro=true";
import { default as _91token_93zp3mx5YuXiMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/password-reset/[token].vue?macro=true";
import { default as privacydlcLN9LuNrMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/privacy.vue?macro=true";
import { default as registerJRbjZbvdzEMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/register.vue?macro=true";
import { default as contact6F2IlcnnmzMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/support/contact.vue?macro=true";
import { default as faqcjNC6gi2fBMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/support/faq.vue?macro=true";
import { default as indexkG29745w1lMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/support/index.vue?macro=true";
import { default as verifyYC8IA8RAwdMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/verify.vue?macro=true";
import { default as welcomeiCpE5L5GizMeta } from "/home/ploi/luidruchtig.wijsneus.app/pages/welcome.vue?macro=true";
export default [
  {
    name: "chat",
    path: "/chat",
    meta: chatdf8mRyzAVdMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/chat.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordMgh7JPxaodMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/forgot-password.vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexntAewQPBcpMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginjr3mrz6PDSMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/login.vue")
  },
  {
    name: "verify.token",
    path: "/password-reset/:token()",
    meta: _91token_93zp3mx5YuXiMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/password-reset/[token].vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacydlcLN9LuNrMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/privacy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerJRbjZbvdzEMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/register.vue")
  },
  {
    name: "contact",
    path: "/support/contact",
    meta: contact6F2IlcnnmzMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/support/contact.vue")
  },
  {
    name: "faq",
    path: "/support/faq",
    meta: faqcjNC6gi2fBMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/support/faq.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexkG29745w1lMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/support/index.vue")
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifyYC8IA8RAwdMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/verify.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeiCpE5L5GizMeta || {},
    component: () => import("/home/ploi/luidruchtig.wijsneus.app/pages/welcome.vue")
  }
]