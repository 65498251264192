import { useStorage } from "@vueuse/core";

export const useChatStore = defineStore("chat", () => {
  // Save messages in local storage
  const id: Ref<string | undefined> = useStorage("chat.id", undefined);
  const messages = useStorage("chat.history", <Message[]>[], undefined, {
    serializer: {
      read: (value: string) => {
        return value ? JSON.parse(value) : null;
      },
      write: (value: Message[]) => {
        return JSON.stringify(value);
      },
    },
  });

  const clear = () => {
    id.value = undefined;
    messages.value = [];
  };

  return {
    id,
    messages,
    clear,
  };
});
