import type { FetchContext } from "ofetch";
import type { ConsolaInstance } from "consola";
import type { NuxtApp } from "#app";
import { getSubdomain } from "./utils";

const tokenStorageKey = "sanctum.storage.token";

export default defineAppConfig({
  sanctum: {
    tokenStorage: {
      get: async () => {
        if (import.meta.server) {
          return undefined;
        }

        return window.localStorage.getItem(tokenStorageKey) ?? undefined;
      },

      set: async (app: NuxtApp, token?: string) => {
        if (import.meta.server) {
          return;
        }

        if (!token) {
          window.localStorage.removeItem(tokenStorageKey);
          return;
        }

        window.localStorage.setItem(tokenStorageKey, token);
      },
    },
    interceptors: {
      onRequest: async (
        app: NuxtApp,
        ctx: FetchContext,
        logger: ConsolaInstance
      ) => {
        // Get assitant id from config
        let assistantId = app.$config.public.assistantId;

        // Try to get assistant id from url or local storage for app.wijsneus.web
        if (app.$config.public.appId === "app.wijsneus.web") {
          assistantId = getSubdomain() || assistantId;

          // const assistantIdStorage = await Preferences.get({
          //   key: "assistantId",
          // });

          // assistantId = assistantIdParam || assistantIdStorage.value || assistantId;

          // // Check if the assistant ID has changed
          // if (assistantIdStorage.value !== assistantId) {
          //   // Logout user
          //   console.log("logout");
          //   const { logout } = useSanctumAuth();
          //   logout();
          //   // Clear preferences
          //   await Preferences.clear();
          //   // Update assistant ID
          //   await Preferences.set({
          //     key: "assistantId",
          //     value: assistantId,
          //   });
          // }
        }

        // Add the asssistant ID to the request headers
        if (assistantId) {
          const headers: HeadersInit = {
            ...ctx.options.headers,
            "X-Assistant-Id": assistantId,
          };
          ctx.options.headers = headers;
          logger.debug(
            `Added header X-Assistant-Id: (${app.$config.public.assistantId})`
          );
        } else {
          throw new Error("No assistant ID found");
        }
      },
    },
  },
});
