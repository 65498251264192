import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const { sentry } = useRuntimeConfig().public;

  if (!sentry.dsn) {
    console.error("Sentry disabled, missing DSN");
    return;
  }

  console.info("Initializing Sentry error logging");

  // const router = useRouter();

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    integrations: [
      // Sentry.browserTracingIntegration({ router }),
      // Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/api\.wijsneus\.app\/api/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
});
