import { useAssistantSettingsStore } from "@/stores/assistantSettingsStore";
import { snakeToKebab } from "@/utils";

interface Theme {
  [name: string]: string;
}

export default defineNuxtPlugin(async () => {
  const sanctumFetch = useSanctumClient();

  const fetchAssistantSettingsData = async () => {
    const assistantSettingsStore = useAssistantSettingsStore();
    const response = await sanctumFetch("/assistant-settings");
    assistantSettingsStore.assistantSettings = response.data;

    applyAssistantTheme(response.data.theme);
  };

  const applyAssistantTheme = (theme: Theme) => {
    if (theme) {
      Object.keys(theme).forEach((key) => {
        const cssVariable = `--${snakeToKebab(key)}`;
        document.documentElement.style.setProperty(cssVariable, theme![key]);
      });
    }
  };

  await fetchAssistantSettingsData();
});
