export const useAssistantSettingsStore = defineStore(
  "assistantSettings",
  () => {
    const assistantSettings = ref<AssistantSettings>({
      auth: {
        has_auto_login: false,
        has_registration: true,
      },
      config: {
        has_branding: "",
      },
      text: {
        welcome_title: "",
        welcome_text: "",
        intro_title: "",
        intro_text: "",
      },
      images: {
        avatar: "",
        logo_dark: "",
        logo_light: "",
      },
      profile: {
        name: "",
      },
      theme: {
        // Main
        main_body_background_color: "",
        main_body_text_color: "",
        main_button_background_color: "",
        main_button_text_color: "",
        main_button_focus_color: "",
        main_input_background_color: "",
        main_input_border_color: "",
        main_input_text_color: "",
        main_input_focus_color: "",
        main_error_color: "",
        main_border_radius: "",
        // Auth
        auth_body_background_color: "",
        auth_body_text_color: "",
        auth_button_background_color: "",
        auth_button_text_color: "",
        auth_button_focus_color: "",
        auth_input_background_color: "",
        auth_input_border_color: "",
        auth_input_text_color: "",
        auth_input_focus_color: "",
        auth_error_color: "",
        // Overlay
        overlay_body_background_color: "",
        overlay_body_text_color: "",
        overlay_button_background_color: "",
        overlay_button_text_color: "",
        overlay_button_focus_color: "",
        overlay_input_background_color: "",
        overlay_input_border_color: "",
        overlay_input_text_color: "",
        overlay_input_focus_color: "",
        overlay_error_color: "",
        // Chat
        chat_body_background_color: "",
        chat_body_text_color: "",
        chat_input_background_color: "",
        chat_input_border_color: "",
        chat_input_text_color: "",
        chat_input_focus_color: "",
        chat_bubble_system_background_color: "",
        chat_bubble_system_text_color: "",
        chat_bubble_assistant_background_color: "",
        chat_bubble_assistant_text_color: "",
        chat_bubble_user_background_color: "",
        chat_bubble_user_text_color: "",
        // Menu
        menu_body_background_color: "",
        menu_body_text_color: "",
      },
    });

    return {
      assistantSettings,
    };
  },
);
